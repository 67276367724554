<script context="module">
    import { fetchWithHeaders, getBaseURL } from "../utils.svelte";

    export async function login(email, rawPassword) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/login', {
            method: 'POST',
            body: JSON.stringify({email, password: rawPassword})
        });
        return apiResp;
    }

    export async function getLoggedInUser() {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/user');
        return apiResp;
    }

</script>