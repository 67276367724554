<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function getReservationDetails(reservationID) {
        return await fetchWithHeaders(getBaseURL() + '/reservations/' + reservationID);
    }
    
    export async function deleteReservation(reservationID) {
        return await fetchWithHeaders(getBaseURL() + '/reservations/' + reservationID, {
            method: 'DELETE'
        });
    }

    export async function createReservationRating(propertyID, reservationID, ratingData) {
        return await fetchWithHeaders(querifiedURL(getBaseURL() + `/properties/${propertyID}/reservations/${reservationID}/ratings`), {
            method: 'POST',
            body: JSON.stringify(ratingData)
        });
    }

    export async function updateReservationRating(propertyID, reservationID, ratingID, ratingData) {
        return await fetchWithHeaders(querifiedURL(getBaseURL() + `/properties/${propertyID}/reservations/${reservationID}/ratings/${ratingID}`), {
            method: 'PUT',
            body: JSON.stringify(ratingData)
        });
    }

    export async function deleteReservationRating(propertyID, reservationID, ratingID) {
        return await fetchWithHeaders(getBaseURL() + `/properties/${propertyID}/reservations/${reservationID}/ratings/${ratingID}`, {
            method: 'DELETE'
        });
    }
</script>