<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function createProperty(propertyData){
        return await fetchWithHeaders(getBaseURL() + '/properties', {
            method: 'POST',
            body: propertyData
        });
    };

    export async function getProperty(propertyID){
        return await fetchWithHeaders(getBaseURL() + '/properties/' + propertyID);
    };

    export async function updateProperty(propertyID, propertyData){
        return await fetchWithHeaders(getBaseURL() + '/properties/' + propertyID, {
            method: 'PUT',
            body: JSON.stringify(propertyData)
        });
    };

    export async function deleteProperty(propertyID){
        return await fetchWithHeaders(getBaseURL() + '/properties/' + propertyID, {
            method: 'DELETE'
        });
    };

    export async function getProperties(forSelect){
        const queryParams = {};
        if (forSelect) {
            queryParams.forSelect = 1;
        }
        return await fetchWithHeaders(querifiedURL(getBaseURL() + '/properties', queryParams));
    };

    export async function getPropertyReservations(propertyID, minDate, maxDate) {
        const queryParams = {};
        if (minDate) {
            queryParams.minDate = minDate;
        }
        if (maxDate) {
            queryParams.maxDate = maxDate;
        }
        return await fetchWithHeaders(querifiedURL(getBaseURL() + '/properties/' + propertyID + '/reservations-overview'));
    };

    export async function calculateMonthlyPropertyEarnings(minDate, maxDate, propertyIDs) {
        if (!propertyIDs) {
            throw new Error("Property ID is required");
        }
        const queryParams = {};
        if (minDate) {
            queryParams.minDate = minDate;
        }
        if (maxDate) {
            queryParams.maxDate = maxDate;
        }
        if (typeof propertyIDs != 'object') {
            queryParams.propertyIDs = [propertyIDs];
        }
        return await fetchWithHeaders(querifiedURL(getBaseURL() + '/properties/calculate-earnings/monthly', queryParams));
    }

    export async function createReservation(propertyID, reservationData) {
        return await fetchWithHeaders(getBaseURL() + `/properties/${propertyID}/reservations`, {
            method: 'POST',
            body: reservationData
        });
    };

    export async function updateReservation(propertyID, reservationID, reservationData) {
        return await fetchWithHeaders(getBaseURL() + `/properties/${propertyID}/update-reservation/${reservationID}`, {
            method: 'POST',
            body: reservationData
        });
    };

    export async function getPropertyRatings(propertyID) {
        return await fetchWithHeaders(querifiedURL(getBaseURL() + `/properties/${propertyID}/ratings`));
    }
</script>