<script>
    import { navigate } from 'svelte-routing';
    import PropertyForm from "./PropertyForm.svelte";
    import { createProperty } from "../../helpers/apiHelper.svelte"
    let isLoading = false;

    const handleSubmit = async e => {
      isLoading = true;
      const propertyFormData = new FormData(e.target);
      if (propertyFormData.get(["images[]"])?.size === 0) {
        propertyFormData.delete("images[]");
      }
      const response = await createProperty(propertyFormData);
      isLoading = false;
      if (response.success) {
        navigate('/properties');
      }
    }
</script>
<PropertyForm
    formMode="new"
    handleSubmit={handleSubmit}
    isLoading={isLoading}
/>