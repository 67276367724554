<script>
    import { onMount } from "svelte";
    import Toast from "../components/Toast/Toast.svelte";
    
    export let message = 'Hata !';
    export let type = null;
    export let timeOut = 3000;
    let visible = true;

    const onClose = () => {
        visible = false;
    }
    onMount(() => {
        if (visible) {
            setTimeout(onClose, timeOut);
        }
    })
</script>
<Toast
    visible={visible}
    type={type}
    message={message}
    onClose={onClose}
/>