import { userStore } from "./user.store";
import { locationStore } from './location.store';
import { notificationStore } from './notification.store';
import * as svStoreFN from 'svelte/store';

if (typeof process === 'undefined' || !process?.env?.PRODUCTION) {
    window.svStore = {
        userStore,
        locationStore,
        notificationStore,
    };
    window.svStoreFN = svStoreFN;
}
export {
    userStore,
    locationStore,
    notificationStore,
};
