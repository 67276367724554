<script>
    import { onMount } from "svelte/internal";
    import { getProperty, updateProperty } from "../../helpers/apiHelper.svelte";
    import PropertyForm from "./PropertyForm.svelte";

    export let propertyID = null;
    let initialValues = null;

    onMount(async () => {
        const response = await getProperty(propertyID);
        initialValues = response.content;
    });

    const handleSubmit = async (e) => {
        const data = Object.fromEntries(new FormData(e.target));
        const updateResp = await updateProperty(propertyID, data);
        // TODO :: Handle update resp, show error if failed, push back if success
    }
</script>
<PropertyForm
    formMode="edit"
    handleSubmit={handleSubmit}
    initialValues={initialValues}
/>