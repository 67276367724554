<script>
    export let bgURL = "";
    export let bgColor = "";
    export let bgSize = "cover";
    export let withPadding = true;
    let style = "";
    if (bgURL) {
        style += `background: url("${bgURL}") no-repeat center center;`;
        style += `background-size: ${bgSize};`;
    }
    if (bgColor) {
        style += `background-color: ${bgColor};`;
    }
</script>
<div class="rounded-xl filter drop-shadow-lg bg-white h-full w-full" style={style}>
    <div class="mb-0"
         class:px-6={withPadding}
         class:py-6={withPadding}>
        <slot></slot>
    </div>
</div>