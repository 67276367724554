<script>
  export let progress = 0; // between 0 - 100
  export let color = "#c5fcc5";
  const angle = 360 * progress / 100;
  // Adapt the logic according to the approach
  const background = `radial-gradient(white 50%, transparent 51%),
  conic-gradient(transparent 0deg ${angle}deg, gainsboro ${angle}deg 360deg),
  conic-gradient(${color} 0deg, ${color} 90deg, ${color} 180deg, ${color});`;
  $: cssVarStyles = `--background:${background}`
</script>

<div style={`width: 200px; height:200px; border-radius: 50%; transition: all 500ms ease-in; will-change: transform; background: ${background}`}></div>