<script>
    import Modal from "../Modal/Modal.svelte";
import Show from "../Show/Show.svelte";
    import ImageViewer from "./ImageViewer.svelte";

    export let file = null;
    export let onClose = null;
    let fileExtension = null;
    let visible = false;

    $: if(file.url) {
        fileExtension = file.url.split('.').pop();
    }

    const iconByFileTypes = {
        csv: 'fas fa-file-csv',
        pdf: 'fas fa-file-pdf',
        xlsx: 'fas fa-file-csv',
        svg: 'fas fa-image',
        txt: 'fas fa-file-alt'
    };

    const onImageClick = () => {
        visible = true;
    }

    const handleClose = () => {
        visible = false;
    }
</script>
<div class="relative w-12 flex justify-center">
    <Show condition={true || typeof onClose === 'function'}>
        <i class="cursor-pointer absolute top-0 right-0 z-10 far fa-times-circle"/>
    </Show>
    {#if iconByFileTypes[fileExtension]}
        <i class="mt-3 text-4xl {iconByFileTypes[fileExtension]}" />
    {:else}
        <ImageViewer
            class="mt-3 w-10 h-10 rounded-full"
            file={file}
            onClick={onImageClick}
        />
        <Modal
            visible={visible}
            onClose={handleClose}
        >
            <ImageViewer
                class="mt-3 w-10 h-10 rounded-full"
                file={file}
            />
        </Modal>
    {/if}
</div>