<script>
    import { onMount } from 'svelte';
    import { get } from 'svelte/store';
    import { navigate } from 'svelte-routing';
    
    // import { deleteAppUser } from "../../helpers/apiHelper.svelte";
    import { getProperties } from "../../helpers/apiHelper.svelte";
    
    import DataTable from "../../components/DataTable/DataTable.svelte";
    import Button from "../../components/Form/Button.svelte";
    import Select from "../../components/Form/Select.svelte";
    import { locationStore, userStore } from "../../store/store.index";
    import PropertyRowOperations from './PropertyRowOperations.svelte';
    
    let propertyList = [];
    let isLoading = true;
    let breadcrumbText = 'Ev Yönetimi';
    let tableTitle = 'Ev Listesi';

    const userData = get(userStore);
    
    const tableColumns = [
        { name: "Ev Adı", keyName: "name" },
        { name: "Ev Sahibi", keyName: "owner.fullname" },
        { name: "Şehir", keyName: "city_name" },
        { name: "Durum", keyName: "status" },
        { name: 'İşlemler', keyName: 'actions', type: 'actions' }
    ];
    
    async function initializeProperties() {
        const resp = await getProperties();
        propertyList = resp?.content;
    }
    
    function onRowEditClick(row) {
        navigate("/properties/edit/" + row.id);
    }
    
    async function onRowDeleteClick(row) {
        isLoading = true;
        // await deleteAppUser(row.id);
        await initializeProperties();
        isLoading = false;
    }

    const onView = (row) => {
        navigate("/properties/view/" + row.id);
    }
    
    const RowOperations = {
        component: PropertyRowOperations,
        props: { 
            onRowEditClick,
            onRowDeleteClick,
            onView,
            userType: userData.user_type
        }
    }
    
    onMount(async () => {
        locationStore.set({breadcrumbText});
        await initializeProperties();
        isLoading = false;
    });

    let headerButtons = null;
    if (userData.user_type === "ADMIN") {
        headerButtons = [
            {
                text: "YENİ EV OLUŞTUR",
                color: "bg-green-500",
                hoverColor: "hover:bg-green-600",
                activeColor: "active:bg-green-700",
                nextRoute: "/properties/new"
            }
        ];
    }
    
</script>
<div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 xl:mb-0 px-4">
        <DataTable
            showLoading={isLoading}
            tableTitle={tableTitle}
            headerButtons={headerButtons}
            columns={tableColumns}
            dataSource={propertyList}
            rowOperations={RowOperations}
            showPagination={true}
        />
    </div>
</div>