<script>
  import { onMount } from "svelte";
  import Card from "../components/Cards/Card.svelte";
  import CircularProgress from "../components/CircularProgress/CircularProgress.svelte";
  import DatePicker from "../components/Form/DatePicker.svelte";
  import LoadingOverlay from "../components/Form/LoadingOverlay.svelte";
  import { generateDashboardReport } from "../helpers/apiHelper.svelte";
  import { formatDate } from "../helpers/utils.svelte";
  import { userStore } from "../store/user.store";

  const now = new Date();
  let showLoading = true;
  let dashboardValues = {
    total_earning: null,
    rating_count: null,
    rating_avg: null
  };
  let selectedMin = formatDate(new Date(now.getFullYear(), now.getMonth(), 1));
  let selectedMax = formatDate(new Date(now.getFullYear(), now.getMonth() + 1, 0));

  async function handleDateChange(targetField, value) {
    if (value) {
      if (targetField === "min") {
        selectedMin = value;
      } else {
        selectedMax = value;
      }
      await initializeDashboardValues();
    }
  }

  async function initializeDashboardValues() {
    showLoading = true;
    const dashboardResp = await generateDashboardReport($userStore.id, selectedMin, selectedMax);
    if (!dashboardResp.sucess || !dashboardResp.content) {
      // TODO :: Show error
    }
    dashboardValues.total_earning = dashboardResp.content.total_earning;
    dashboardValues.rating_count = dashboardResp.content.rating_count;
    dashboardValues.rating_avg = dashboardResp.content.rating_avg;
    showLoading = false;
  }

  onMount( async () => {
    if ($userStore.id) {
      await initializeDashboardValues();
    } else {
      // TODO :: Show invalid user error
    }
  });
</script>

<div class="min-h-screen-w-header bg-white">
  
  <LoadingOverlay showOverlay={showLoading}/>
  <div class="p-4">
    <div class="w-full px-4 mb-4">
      <p class="text-xl text-center uppercase text-blueGray-600 font-bold">{formatDate(selectedMin, "d.m.Y")} - {formatDate(selectedMax, "d.m.Y")} Tarihlerindeki İstatistikler</p>
    </div>
  </div>
  <div class="p-4">
    <div class="flex flex-wrap">
      <div class="w-full lg:w-6/12 px-4 mb-4">
        <DatePicker onChange={(e) => handleDateChange("min", e.target.value)} value={selectedMin} labelText="Başlangıç Tarihi" />
      </div>
      <div class="w-full lg:w-6/12 px-4 mb-4">
        <DatePicker onChange={(e) => handleDateChange("max", e.target.value)} value={selectedMax} labelText="Bitiş Tarihi"/>
      </div>
    </div>
  </div>
  <div class="p-4">
    <div class="flex flex-wrap">
      <div class="w-full px-4 mb-4">
        <Card>
          <p class="text-blueGray-600 text-lg font-semibold text-center pb-4">Net Kazanç</p>
          {#if !dashboardValues.total_earning}
            <div style="height:200px;" class="flex justify-center items-center">
              <p class="font-thin text-md">Seçilen tarihlere ait veri bulunamadı</p>
            </div>
          {:else}
            <div style="height:200px; background: url('/assets/img/net-income-bg.png') no-repeat center center;background-size: contain;background-color: #FFF;" class="flex justify-center items-center">
              <p class="font-extrabold text-lg">{dashboardValues.total_earning}₺</p>
            </div>
          {/if}
        </Card>
      </div>
      <div class="w-full lg:w-6/12 px-4 mb-4">
        <Card>
          <p class="text-blueGray-600 text-lg font-semibold text-center pb-4">
            Doluluk Oranı
          </p>
          <div style="height:200px;" class="flex justify-center items-center flex-col">
            {#if !dashboardValues.reservation_fill_rate}
              <p class="font-thin text-md">Seçilen tarihlere ait veri bulunamadı</p>
            {:else}
              <CircularProgress/>
            {/if}
          </div>
        </Card>
      </div>
      <div class="w-full lg:w-6/12 px-4 mb-4">
        <Card>
          <p class="text-blueGray-600 text-lg font-semibold text-center pb-4">Yapılan Değerlendirmeler</p>
          {#if !dashboardValues.rating_count}
            <div style="height:200px;" class="flex justify-center items-center flex-col">
              <p class="font-thin text-md">Seçilen tarihler arasında değerlendirme yapılmamış</p>
            </div>
          {:else}
            <div style="height:200px;" class="flex justify-center items-center flex-col">
              <p class="font-extrabold text-md">Değerlendirme Sayısı</p>
              <p class="font-extrabold text-lg">{dashboardValues.rating_count}</p>
              <br>
              <p class="font-extrabold text-md">Değerlendirme Ortalaması</p>
              <p class="font-extrabold text-lg">{dashboardValues.rating_avg} / 5</p>
            </div>
          {/if}
        </Card>
      </div>
    </div>
  </div>
</div>
