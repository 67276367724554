<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import {
      TextInput,
      Button,
      AutoComplete,
      Loading,
      Textarea,
      FileUpload,
      FileViewer,
      Select
    } from "../../components/index.svelte";
    import Show from "../../components/Show/Show.svelte";
    import { getCities, getPropertyOwners } from "../../helpers/apiHelper.svelte";

    export let handleSubmit = null;
    export let initialValues = {};
    export let isLoading = false;
    export let formMode = "new";
    let cities = [];
    let propertyOwners = [];

    onMount(async () => {
      const cityResponse = await getCities();
      const propertyOwnerResponse = await getPropertyOwners(true);
      cities = cityResponse.content;
      propertyOwners = propertyOwnerResponse.content;
    });

</script>
<div class="container mx-auto mt-4">
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y">
    <Loading loading={isLoading} />
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form id="new-property-form" on:submit|preventDefault={handleSubmit}>
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {formMode === "new" ? "Yeni Mülkiyet" : "Ev Düzenle"}
            </h6>
            <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 p-4">
                  <TextInput
                    fieldName="name"
                    labelText="Ev İsmi"
                    required
                    value={initialValues?.name}
                  />
                </div>
                <div class="w-full lg:w-6/12 p-4">
                  <AutoComplete
                    fieldName={'user_id'}
                    labelText="Ev Sahibi"
                    labelField={['firstname', 'lastname']}
                    valueField="id"
                    placeholder="Lütfen seçiniz"
                    selectOptions={propertyOwners}
                    value={initialValues?.user_id}
                  />
                </div>
                <div class="w-full lg:w-6/12 p-4">
                  <Select fieldName="is_active" labelText="Rezervasyon Durumu" value={initialValues?.is_active} selectOptions={[
                    { text: 'Aktif', value: 1 },
                    { text: 'Pasif', value: 0 }
                  ]} required/>
                </div>
                <div class="w-full lg:w-6/12 p-4">
                  <TextInput
                    fieldName='commission_rate'
                    labelText='Komisyon'
                    value={initialValues?.commission_rate}
                  />
                </div>
                <div class="w-full lg:w-6/12 p-4">
                  <AutoComplete
                    fieldName={'city_id'}
                    labelText="Şehir"
                    labelField="name"
                    valueField="id"
                    placeholder="Lütfen seçiniz"
                    selectOptions={cities}
                    value={initialValues?.city_id}
                  />
                </div>
                <div class="w-full lg:w-6/12 p-4">
                  <TextInput
                    fieldName="airbnb_id"
                    labelText="Airbnb ID"
                    required
                    value={initialValues?.airbnb_id}
                  />
                </div>
                <div class="w-full p-4">
                  <Textarea
                    fieldName='description'
                    labelText='Açıklama'
                    value={initialValues?.description}
                  />
                </div>
                <div class="w-full p-4">
                  <Textarea
                    fieldName='address'
                    labelText='Adres'
                    value={initialValues?.address}
                  />
                </div>
                <Show condition={initialValues?.files?.gallery}>
                  <div class="flex justify-around w-full p-4">
                    {#each initialValues.files.gallery as file}
                      <FileViewer file={file}/>
                    {/each}
                  </div>
                </Show>
                <div class="w-full p-4">
                  <FileUpload
                    labelText='Ev Fotoğrafları'
                    fieldName='images[]'
                  />
                </div>
                <div class="flex justify-end flex-auto px-4 lg:px-10 py-6 ">
                  <div class="flex-none">
                    <Button text="Kaydet" submitsForm="new-property-form" />
                    <Button text="Vazgeç" buttonType="cancel" onClick={ () => navigate("/properties") } />
                  </div>
                </div>
              </div>
        </form>
    </div>
  </div>
</div>