<script>
    import Show from "../Show/Show.svelte";

    export let labelText = '';
</script>
<div class="flex mx-4 my-2">
    <Show condition={labelText}>
        <h1 class="block uppercase text-blueGray-600 text-sm font-bold">
            {labelText}:
        </h1>
    </Show>
    <span class="ml-2 text-sm">
        <slot />
    </span>
</div>