<script>
    import Show from "../Show/Show.svelte";

    export let title = null;
    export let imageSource = null;
</script>
<div class="block w-full items-center bg-white border rounded shadow-lg my-4">
    <Show condition={title}>
        <h3 class="p-4 font-semibold text-base text-blueGray-700">{title}</h3>
        <hr />
    </Show>
    <div class:flex={imageSource}>
        <Show condition={imageSource}>
            <img class="object-contain w-1/2 rounded-t-lg max-h-64" src={imageSource} alt="">
        </Show>
        <div class="p-4 flex flex-col justify-center">
            <slot />
        </div>
    </div>
</div>