<script>
    import { navigate } from "svelte-routing";
    import { onMount } from "svelte/internal";
    import InfoCard from "../../components/Cards/InfoCard.svelte";
    import LineChart from "../../components/Charts/LineChart.svelte";
    import { locationStore } from "../../store/location.store";
    import { userStore } from "../../store/user.store";
    import DataTable from "../../components/DataTable/DataTable.svelte";
    import DescriptionViewer from "../../components/DescriptionViewer/DescriptionViewer.svelte";
    import {
        getProperty,
        getPropertyReservations,
        calculateMonthlyPropertyEarnings,
        deleteReservation,
        getPropertyRatings,
        deleteReservationRating
    } from "../../helpers/apiHelper.svelte";
    import ReservationRowOperations from "./ReservationRowOperations.svelte";
    import { MONTHS } from '../../helpers/constants';
    import RatingRowOperations from "./RatingRowOperations.svelte";
    import RatingForm from "./RatingForm.svelte";
    import Modal from "../../components/Modal/Modal.svelte";


    export let propertyID = null;
    let propertyDetails = null;
    let reservationDetails = null;
    let chartData = null;
    let ratingDetails = null;
    let ratingFormVisible = false;
    let selectedRating = null;

    onMount(async () => {
        $locationStore.breadcrumbText = "Ev Detayları";
        const propertyResponse = await getProperty(propertyID);
        await initializeReservations();
        await initializeRatings();
        propertyDetails = propertyResponse?.content;
        const earningsResponse = await calculateMonthlyPropertyEarnings(null, null, propertyID);
        const earningsDetails = earningsResponse?.content?.calculation?.sort((a,b) => new Date(a.months).getTime() - new Date(b.months).getTime());
        chartData = earningsDetails?.reduce((prev, curr) => {
            const [year, month] = curr.months.split('-');
            const monthName = MONTHS[parseInt(month, 10)];
            const monthlyEarning = curr.monthly_earning || 0;
            prev.labels.push(`${monthName} - ${year}`);
            prev.datasets[0].data.push(monthlyEarning);
            return prev;
        }, {
            labels: [],
            datasets: [{
                label: 'Earning',
                data: [],
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            }]
        })
    });

    async function initializeReservations() {
        const reservationResponse = await getPropertyReservations(propertyID);
        reservationDetails = reservationResponse?.content;
    }

    async function initializeRatings() {
        const ratingsResponse = await getPropertyRatings(propertyID);
        ratingDetails = ratingsResponse?.content;
    }

    const reservationColumns = [
        { name: "Misafir Adı Soyadı", keyName: "guest_name" },
        { name: "Check In", keyName: "check_in" },
        { name: "Check Out", keyName: "check_out" },
        { name: "Yetişkin Sayısı", keyName: "adult_count" },
        { name: "Toplam Kazanç", keyName: "earning" },
    ];
    if ($userStore.user_type === "ADMIN") {
        reservationColumns.push({ name: 'İşlemler', keyName: 'actions', type: 'actions' });
    }
    const ratingColumns = [
        { name: "Misafir Adı Soyadı", keyName: "guest_name" },
        { name: "Accuracy", keyName: "accuracy" },
        { name: "Communication", keyName: "communication" },
        { name: "Cleanliness", keyName: "cleanliness" },
        { name: "Location", keyName: "location" },
        { name: "Check In", keyName: "check_in" },
        { name: "Val", keyName: "val" },
        { name: "Overall", keyName: "overall" },
        { name: "Yorum", keyName: "guest_comment" },
        { name: "Değerlendirme Tarihi", keyName: "created_at" },
    ];
    if ($userStore.user_type === "ADMIN") {
        ratingColumns.push({ name: 'İşlemler', keyName: 'actions', type: 'actions' });
    }

    function onReservationRowEditClick(row) {
        navigate(`/properties/${propertyID}/reservations/${row.id}/edit`);
    }

    async function onReservationRowDeleteClick(row) {
        const deleteResponse = await deleteReservation(row.id);
        await initializeReservations();
    }

    function onNewRatingClick(reservation) {
        selectedRating = {
            property_id: propertyID,
            reservation_id: reservation.id
        };
    }

    function onRatingRowEditClick(row) {
        selectedRating = {
            id: row.id,
            reservation_id: row.reservation_id,
            property_id: propertyID,
            data: row
        };
    }

    async function onRatingRowDeleteClick(row) {
        const deleteResponse = await deleteReservationRating(propertyID, row.reservation_id, row.id);
        await initializeRatings();
    }

    function closeRatingForm() {
        selectedRating = null;
    }

    async function onRatingSubmit() {
        await initializeRatings();
        selectedRating = null;
    }


</script>
<div class="container mx-auto mt-4">
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <InfoCard
            title={propertyDetails?.name}
            imageSource="/assets/img/dodo-logo.png"
        >
            <DescriptionViewer labelText="Ev Sahibi">
                {propertyDetails?.owner?.fullname}
            </DescriptionViewer>
            <DescriptionViewer labelText="Adres">
                {propertyDetails?.address}
            </DescriptionViewer>
            <DescriptionViewer labelText="Şehir">
                {propertyDetails?.city_name}
            </DescriptionViewer>
            <DescriptionViewer labelText="Komisyon">
                {propertyDetails?.commission_rate}
            </DescriptionViewer>
            <DescriptionViewer labelText="Durumu">
                {propertyDetails?.is_active ? 'Uygun' : 'Dolu'}
            </DescriptionViewer>
        </InfoCard>
        <InfoCard title="Aylık Kazanç Grafiği" >
            <LineChart data={chartData} />
        </InfoCard>
        <DataTable
            headerButtons={$userStore.user_type === "ADMIN" ? [
                {
                text: "YENİ REZERVASYON OLUŞTUR",
                color: "bg-green-500",
                hoverColor: "hover:bg-green-600",
                activeColor: "active:bg-green-700",
                nextRoute: `/properties/${propertyID}/reservations/new`
                }
            ] : null}
            tableTitle="Rezervasyonlar"
            columns={reservationColumns}
            rowOperations={
                {
                    component: ReservationRowOperations,
                    props: {
                        onNewRatingClick: onNewRatingClick,
                        onRowEditClick: onReservationRowEditClick,
                        onRowDeleteClick: onReservationRowDeleteClick,
                        userType: 'ADMIN' // TODO
                    }
                }
            }
            dataSource={reservationDetails}
        />
        <DataTable
            tableTitle="Değerlendirmeler"
            columns={ratingColumns}
            rowOperations={
                {
                    component: RatingRowOperations,
                    props: { 
                        onRowEditClick: onRatingRowEditClick,
                        onRowDeleteClick: onRatingRowDeleteClick,
                        userType: 'ADMIN' // TODO
                    }
                }
            }
            dataSource={ratingDetails}
        />
    </div>
</div>
<Modal
    visible={selectedRating}
    onClose={closeRatingForm}
    title={selectedRating?.id ? "DEĞERLENDİRME BİLGİLERİNİ DÜZENLE" : "YENİ DEĞERLENDİRME OLUŞTUR"}
>
    <RatingForm
        reservationID={selectedRating.reservation_id}
        propertyID={selectedRating.property_id}
        ratingID={selectedRating?.id}
        initialValues={selectedRating?.data}
        onRatingSubmit={onRatingSubmit}
        onModalClose={closeRatingForm}
    />
</Modal>