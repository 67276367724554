<script context="module">
    import { notification } from "../notification-handlers/notification.svelte";


    export function setCookie(cookieName, cookieData) {
        document.cookie =
            cookieName +
            "=" +
            cookieData +
            ';expires=Thu, 31 Dec 2099 00:00:00 UTC; path="/"';
    }

    export function removeCookie(cookieName) {
        document.cookie = cookieName+'=; Max-Age=-99999999;';  
    }

    export function getCookie(cookieName) {
        var cookies = document.cookie.split(";");
        if (cookies?.length > 0) {
            for (let cookieStr of cookies) {
                var cookieSelector = cookieName + "=";
                if (cookieStr.trim().startsWith(cookieSelector.trim())) {
                    return cookieStr.replaceAll(cookieSelector, "");
                }
            }
        }
        return null;
    }

    const responseTypes = {
        "application/json": (response) => response.json(),
        "text/plain": (response) => response.text(),
    };
    // TODO: UPDATE FOR POST REQUESTS
    // const updateApiCache = async (url = '', method = '') => {
    //     if (method === 'POST')
    // }

    const cacheKeys = [
        '/user/',
        'system/predefined-values'
    ]

    export async function fetchWithHeaders(...args) {
        const cacheKey = cacheKeys.filter(item => args[0].includes(item))?.[0];
        if (cacheKey && window.apiCache?.[cacheKey]) {
            return window.apiCache[cacheKey];
        }
        const requestHeaders = {
            Authorization: getCookie("dodouc"),
        };
        if (args[1]) {
            args[1].headers = requestHeaders;
        } else {
            args[1] = {
                headers: requestHeaders,
            };
        }
        try {
            const fetchResponse = await fetch(...args);
            if (fetchResponse) {
                const responseType = fetchResponse.headers.get("Content-Type");
                const responsePromise = responseTypes[responseType](fetchResponse) || fetchResponse;
                const response = await responsePromise;
                if (!fetchResponse.ok && (fetchResponse.status < 200 || fetchResponse.status > 399)) {
                    notification.modal({
                        title: 'Hata !',
                        message: response.message
                    });
                    return { success: false };
                }
                if (cacheKey) {
                    window.apiCache = {...window?.apiCache, [cacheKey]: response}
                }
                return response;
            }
            return fetchResponse;
        } catch (err) {
            console.log(`API Error. URL: ${args[0]} `, err);
            notification.modal({
                title: 'Hata !',
                message: 'SİSTEM HATASI !'
            });
        }
    }

    export const elementInViewPort = (element = null) => {
        if (element) {
            const bounding = element.getBoundingClientRect();
            if (bounding.top >= 0 && bounding.left >= 0 && bounding.right <= window.innerWidth && bounding.bottom <= window.innerHeight) {
                return true;
            } else {
                return false;
            }
        }
    }

    export function querifiedURL(url, params) {
        if (typeof params === "object" && params && Object.keys(params).length > 0) {
            params = new URLSearchParams(params);
            return `${url}?${params}`;
        }
        return url;
    }

    export function getBaseURL() {
        let baseURL = "https://ci.evinidodola.com";
        return baseURL;
    }

    export function filesToFormData(files = []) {
        const formData = new FormData();
        for (const file of files) {
            formData.append('images', file);
        };
        return formData;
    }

    export function formatDate(date, format = "Y-m-d") {
        if (typeof date === "string") {
            date = new Date(date);
        }
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        switch (format) {
            case "d.m.Y":
                return `${day}.${month}.${year}`;
            case "Y-md-d":
            default:
                return `${year}-${month}-${day}`;
        }
    }
</script>
