<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { locationStore } from "../../store/location.store";
    import {
      TextInput,
      Button,
      Loading,
      Textarea,
      DatePicker
    } from "../../components/index.svelte";
    import { createReservation, getProperty, getReservationDetails, updateReservation } from "../../helpers/apiHelper.svelte";

    export let propertyID;
    export let reservationID;
    export let initialValues = {};
    export let isLoading = false;
    export let formMode = "new";

    let propertyDetails;

    onMount(async () => {
      $locationStore.breadcrumbText = "Rezervasyon Formu";
        isLoading = true;
        const propertyResponse = await getProperty(propertyID);
        propertyDetails = propertyResponse?.content;
        isLoading = false;
        if (reservationID) {
          const reservationResponse = await getReservationDetails(reservationID);
          if (!reservationResponse.success || !reservationResponse.content) {
            // TODO :: show error
          } else {
            initialValues = reservationResponse.content;
          }
        }
    });

    async function handleSubmit(e) {
        const reservationFormData = new FormData(e.target);
        if (reservationFormData.get(["images[]"])?.size === 0) {
            reservationFormData.delete("images[]");
        }
        let response = null;
        if (reservationID) {
          response = await updateReservation(propertyID, reservationID, reservationFormData);
        } else {
          response = await createReservation(propertyID, reservationFormData);
        }
        if (response.success) {
          // TODO :: SHOW SUCCESS TOAST
          navigate(`/properties/view/${propertyID}`);
        }
        isLoading = false;
    }

</script>
<div class="container mx-auto mt-4">
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y">
    <Loading loading={isLoading} />
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form id="new-reservation-form" on:submit|preventDefault={handleSubmit}>
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {formMode === "new" ? "Yeni Rezervasyon" : "Rezervasyon Düzenle"}
            </h6>
            <div class="flex flex-wrap">
                <div class="w-full p-4">
                  <TextInput
                    fieldName="guest_name"
                    labelText="Misafirin Adı Soyad"
                    required
                    value={initialValues?.guest_name}
                  />
                </div>
                <div class="w-full lg:w-6/12 p-4">
                    <TextInput
                      fieldName="guest_email"
                      labelText="Misafirin Email Adresi"
                      required
                      value={initialValues?.guest_email}
                    />
                </div>
                <div class="w-full lg:w-6/12 p-4">
                    <TextInput
                      fieldName="guest_phone"
                      labelText="Misafirin Telefon Numarası"
                      required
                      value={initialValues?.guest_phone}
                    />
                </div>
                <div class="w-full lg:w-3/12 p-4">
                    <TextInput
                      fieldName="adult_count"
                      labelText="Yetişkin Sayısı"
                      required
                      value={initialValues?.adult_count}
                    />
                </div>
                
                <div class="w-6/12 lg:w-3/12 p-4">
                    <TextInput
                      fieldName="child_count"
                      labelText="Çocuk Sayısı"
                      value={initialValues?.child_count || 0}
                    />
                </div>

                <div class="w-6/12 lg:w-3/12 p-4">
                    <TextInput
                      fieldName="baby_count"
                      labelText="Bebek Sayısı"
                      value={initialValues?.baby_count || 0}
                    />
                </div>

                <div class="w-6/12 lg:w-3/12 p-4">
                    <TextInput
                      fieldName="animal_count"
                      labelText="Hayvan Sayısı"
                      value={initialValues?.animal_count || 0}
                    />
                </div>
                <div class="w-full p-4">
                    <Textarea
                      fieldName='notes'
                      labelText='Bilgi Notları'
                      value={initialValues?.notes}
                    />
                </div>
                <div class="w-full lg:w-6/12 px-4 mb-4">
                    <DatePicker
                        fieldName="check_in"
                        onChange={(e) => null}
                        value={initialValues?.check_in}
                        labelText="Check In Tarihi"
                    />
                </div>
                <div class="w-full lg:w-6/12 px-4 mb-4">
                    <DatePicker
                        fieldName="check_out"
                        onChange={(e) => null}
                        value={initialValues?.check_out}
                        labelText="Check Out Tarihi"
                    />
                  </div>
                <div class="w-6/12 p-4">
                  <TextInput
                    fieldName='total_price'
                    labelText='Toplam Tutar'
                    type="number"
                    value={initialValues?.total_price}
                  />
                </div>
                <div class="w-6/12 p-4">
                  <TextInput
                    fieldName='commission_rate'
                    labelText='Komisyon Oranı'
                    type="number"
                    value={initialValues?.commission_rate || propertyDetails?.commission_rate}
                  />
                </div>
                <div class="flex justify-end flex-auto px-4 lg:px-10 py-6 ">
                  <div class="flex-none">
                    <Button text="Kaydet" submitsForm="new-reservation-form" />
                    <Button text="Vazgeç" buttonType="cancel" onClick={() => navigate(`/properties/view/${propertyID}`)} />
                  </div>
                </div>
              </div>
        </form>
    </div>
  </div>
</div>