<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function getPropertyOwner(userID){
        return await fetchWithHeaders(getBaseURL() + '/users/property-owners/' + userID);
    };

    export async function createPropertyOwner(userData){
        return await fetchWithHeaders(getBaseURL() + '/users', {
            method: 'POST',
            body: JSON.stringify(userData)
        });
    };

    export async function updatePropertyOwner(userID, userData){
        return await fetchWithHeaders(getBaseURL() + '/users/property-owners/' + userID, {
            method: 'PUT',
            body: JSON.stringify(userData)
        });
    };

    export async function deletePropertyOwner(userID){
        return await fetchWithHeaders(getBaseURL() + '/users/property-owners/' + userID, {
            method: 'DELETE'
        });
    };

    export async function getPropertyOwners(forSelect){
        const queryParams = {};
        if (forSelect) {
            queryParams.forSelect = 1;
        }
        return await fetchWithHeaders(querifiedURL(getBaseURL() + '/users/property-owners', queryParams));
    };

    export async function getSystemUser(userID){
        return await fetchWithHeaders(getBaseURL() + '/users/system-users/' + userID);
    };

    export async function createSystemUser(userData){
        return await fetchWithHeaders(getBaseURL() + '/users', {
            method: 'POST',
            body: JSON.stringify(userData)
        });
    };

    export async function updateSystemUser(userID, userData){
        return await fetchWithHeaders(getBaseURL() + '/users/system-users/' + userID, {
            method: 'PUT',
            body: JSON.stringify(userData)
        });
    };

    export async function deleteSystemUser(userID){
        return await fetchWithHeaders(getBaseURL() + '/users/system-users/' + userID, {
            method: 'DELETE'
        });
    };

    export async function getSystemUsers(forSelect){
        const queryParams = {};
        if (forSelect) {
            queryParams.forSelect = 1;
        }
        return await fetchWithHeaders(querifiedURL(getBaseURL() + '/users/system-users', queryParams));
    };

    export async function generateDashboardReport(userID, minDate, maxDate) {
        const queryParams = {
            minDate,
            maxDate
        };
        return await fetchWithHeaders(querifiedURL(getBaseURL() + '/users/' + userID + '/generate-dashboard-report', queryParams));
    }

</script>