<script>
import { onMount } from 'svelte';
import { navigate } from 'svelte-routing';

import {
  updatePropertyOwner,
  updateSystemUser,
  getPropertyOwner,
  getSystemUser,
  createPropertyOwner,
  createSystemUser
} from '../../helpers/apiHelper.svelte';

import TextInput from "../../components/Form/TextInput.svelte";
import DatePicker from "../../components/Form/DatePicker.svelte";
import RadioList from "../../components/Form/RadioList.svelte";
import Select from "../../components/Form/Select.svelte";
import Button from "../../components/Form/Button.svelte";
import LoadingOverlay from "../../components/Form/LoadingOverlay.svelte";

export let userID = -1;
export let userType = null;

let userMethods = {
  getter: getPropertyOwner,
  creator: createPropertyOwner,
  updater: updatePropertyOwner
}

if (userType === "system-user") {
  userMethods = {
    getter: getSystemUser,
    creator: createSystemUser,
    updater: updateSystemUser
  }
}

let formTitle = userID > -1 ? 'Kullanıcı Bilgilerini Düzenle' : 'Yeni Kullanıcı Oluştur';
let saveButtonText = userID > -1 ? 'Kaydet' : 'Oluştur';
let showLoading = false;
let errorMessage = "";
let userDetails = {
  is_active: "1",
  has_email_subscription: "1",
  has_notification_subscription: "1"
};


async function handleSubmit(e) {
  showLoading = true;

  const userFormData = Object.fromEntries(new FormData(e.target));
  userFormData.user_type = userType === "system-user" ? "ADMIN" : "USER";
  let saveResp = null;

  if (userID > -1) {
    saveResp = await userMethods.updater(userID, userFormData);
  } else {
    saveResp = await userMethods.creator(userFormData);
  }
  const saveResult = saveResp;

  showLoading = false;

  if (saveResp.status !== 200) {
  //TODO: Show error message on form footer
  }
  if (saveResult?.success) {
    // TODO: Push notification to screen
    window.scrollTo({top: 0, behavior: 'smooth'});
    return;
  } else if (saveResult?.message) {
    errorMessage = saveResult?.message;
  }
}

onMount(async () => {
  showLoading = true;
  if (userID > -1) {
    const userResponse = await userMethods.getter(userID);
    if (!userResponse?.success) {
      errorMessage = userResponse.message;
    }
    userDetails = userResponse?.content?.user;
  }
  showLoading = false;
});

function onCancelClick() {
  window.scrollTo({top: 0, behavior: 'smooth'});
  navigate('/user-management/users');
}
</script>

<div class="container mx-auto mt-4">
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y"
  >
    <LoadingOverlay showOverlay={showLoading}/>
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          {formTitle}
        </h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form id="new-user-form" on:submit|preventDefault={handleSubmit}>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Kullanıcı Bilgileri
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4 mb-4">
            <TextInput
              fieldName="firstname"
              labelText="Ad"
              required
              value={userDetails?.firstname}
              type="tel"
            />
          </div>
          <div class="w-full lg:w-6/12 px-4 mb-4">
            <TextInput
              fieldName="lastname"
              labelText="Soyad"
              required
              value={userDetails?.lastname}
              type="tel"
            />
          </div>
          <div class="w-full px-4 mb-4">
            <TextInput
              fieldName="email"
              labelText="E-mail"
              required
              value={userDetails?.email}
              type="tel"
            />
          </div>
          <div class="w-full lg:w-6/12 px-4 mb-4">
            <DatePicker fieldName="birth_date" value={userDetails?.birth_date} labelText="Doğum Tarihi" required/>
          </div>
          <div class="w-full lg:w-6/12 px-4 mb-4">
            <Select fieldName="gender" labelText="Cinsiyet" value={userDetails?.gender} selectOptions={[
              { text: "Erkek", value: "M"},
              { text: "Kadın", value: "F"}
            ]} required/>
          </div>
          <div class="w-full lg:w-6/12 px-4 mb-4">
            <Select fieldName="city_id" labelText="Şehir" value={userDetails?.city_id} selectOptions={[
              { text: "Ankara", value: 6},
              { text: "İstanbul", value: 34}
            ]} required/>
          </div>
          <div class="w-full lg:w-6/12 px-4 mb-4">
            <Select fieldName="lang" labelText="Dil" value={userDetails?.lang} selectOptions={[
              { text: "Türkçe", value: "tr"},
              { text: "İngilizce", value: "en"}
            ]} required/>
          </div>
          <div class="w-full px-4 mb-4">
            <RadioList labelText="KULLANICI DURUMU" groupName="is_active" value={userDetails?.is_active} options={[
              { label: "Aktif", value: "1" },
              { label: "Pasif", value: "0" }
            ]} />
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Bildirim Seçenekleri
        </h6>

        <div class="flex flex-wrap">
          <div class="w-full px-4 mb-4">
            <RadioList labelText="BİLDİRİM ABONELİĞİ" groupName="has_notification_subscription" value={userDetails?.has_notification_subscription} options={[
              { label: "Etkin", value: "1" },
              { label: "Devre Dışı", value: "0" }
            ]} />
          </div>


          <div class="w-full px-4 mb-4">
            <RadioList labelText="EMAIL ABONELİĞİ" groupName="has_email_subscription" value={userDetails?.has_email_subscription} options={[
              { label: "Etkin", value: "1" },
              { label: "Devre Dışı", value: "0" }
            ]} />
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />
      </form>
    </div>
    <div class="flex justify-end flex-auto px-4 lg:px-10 py-6 ">
      <div class="flex-1">
        {errorMessage}
      </div>
      <div class="flex-none">
        <Button text={saveButtonText} submitsForm="new-user-form" buttonType={userID > -1 ? 'edit' : 'save'} />
        <Button text="Vazgeç" buttonType="cancel" onClick={onCancelClick} />
      </div>
    </div>
  </div>
</div>
