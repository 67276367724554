<script>
    import {
      TextInput,
      Button,
      Textarea,
    } from "../../components/index.svelte";
    import {
      createReservationRating,
      updateReservationRating
    } from "../../helpers/apiHelper.svelte";

    export let propertyID;
    export let reservationID;
    export let ratingID;
    export let initialValues = {};
    export let onRatingSubmit;
    export let onModalClose = () => null;

    async function handleSubmit(e) {
      const ratingData = Object.fromEntries(new FormData(e.target));
      let ratingResp;
      if (ratingID) {
        ratingResp = await updateReservationRating(propertyID, reservationID, ratingID, ratingData);
      } else {
        ratingResp = await createReservationRating(propertyID, reservationID, ratingData);
      }
      if (ratingResp.success) {
        onRatingSubmit();
      }
    }

</script>
<form id="rating-form" on:submit|preventDefault={handleSubmit}>
  <div class="flex flex-wrap">
      <div class="w-full p-4">
        <TextInput
          type="number"
          fieldName="accuracy"
          labelText="Accuracy"
          required
          value={initialValues?.accuracy}
        />
      </div>
      <div class="w-full p-4">
        <TextInput
          type="number"
          fieldName="communication"
          labelText="Communication"
          required
          value={initialValues?.communication}
        />
      </div>
      <div class="w-full p-4">
        <TextInput
          type="number"
          fieldName="cleanliness"
          labelText="Cleanliness"
          required
          value={initialValues?.cleanliness}
        />
      </div>
      <div class="w-full p-4">
        <TextInput
          type="number"
          fieldName="location"
          labelText="Location"
          required
          value={initialValues?.location}
        />
      </div>
      <div class="w-full p-4">
        <TextInput
          type="number"
          fieldName="val"
          labelText="Value"
          required
          value={initialValues?.val}
        />
      </div>
      <div class="w-full p-4">
        <TextInput
          type="number"
          fieldName="check_in"
          labelText="Check In"
          required
          value={initialValues?.check_in}
        />
      </div>
      <div class="w-full p-4">
        <TextInput
          type="number"
          fieldName="overall"
          labelText="Overall"
          required
          value={initialValues?.overall}
        />
      </div>
      <div class="w-full p-4">
        <Textarea
          fieldName="guest_comment"
          labelText="Misafir Yorumu"
          value={initialValues?.guest_comment}
        />
      </div>
      <div class="flex justify-end flex-auto px-4 lg:px-10 py-6 ">
        <div class="flex-none">
          <Button text="Kaydet" submitsForm="rating-form" />
          <Button text="Vazgeç" buttonType="cancel" onClick={onModalClose} />
        </div>
      </div>
    </div>
</form>