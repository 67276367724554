<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';

  // import { deleteAppUser } from "../../helpers/apiHelper.svelte";
  import { getPropertyOwners, getSystemUsers } from "../../helpers/apiHelper.svelte";

  import DataTable from "../../components/DataTable/DataTable.svelte";
  import Button from "../../components/Form/Button.svelte";
  import Select from "../../components/Form/Select.svelte";
  import { locationStore } from "../../store/store.index";
  import RowOperations from './RowOperations.svelte';

  let userList = [];
  let isLoading = true;
  export let listType = null;

  let userGetter = getPropertyOwners;
  let breadcrumbText = 'Ev Sahibi Yönetimi';
  let tableTitle = 'Ev Sahipleri';

  if (listType === "system-users") {
    userGetter = getSystemUsers;
    breadcrumbText = 'Sistem Kullanıcısı Yönetimi';
    tableTitle = 'Sistem Kullanıcıları';
  }
  const tableColumns = [
    { name: "Ad", keyName: "firstname" },
    { name: "Soyad", keyName: "lastname" },
    { name: "Email", keyName: "email" },
    { name: "Cinsiyet", keyName: "gender" },
    { name: "Aktif/Pasif", keyName: "is_active" },
    { name: 'İşlemler', keyName: 'actions', type: 'actions' }
  ];

  async function initializeUsers() {
    const resp = await userGetter();
    if (resp?.content && resp?.content.length) {
      userList = resp?.content.map( (i1) => {
        if (i1.gender === "M") {
          i1.gender = "Erkek";
        } else if (i1.gender === "F") {
          i1.gender = "Kadın";
        } else {
          i1.gender = "Belirtilmemiş"
        }
        if (i1.is_active) {
          i1.is_active = "Aktif";
        } else {
          i1.is_active = "Pasif";
        }
        return i1;
      });
    }
  }

  function onRowEditClick(row) {
    if (listType === "system-users") {
      navigate("/user-management/system-users/" + row.id + "/edit");
    } else {
      navigate("/user-management/property-owners/" + row.id + "/edit");
    }
  }

  async function onRowDeleteClick(row) {
    isLoading = true;
    // await deleteAppUser(row.id);
    await initializeUsers();
    isLoading = false;
  }
  
  const rowOperations = {
    component: RowOperations,
    props: { onRowEditClick, onRowDeleteClick }
  }

  onMount(async () => {
    locationStore.set({breadcrumbText});
    await initializeUsers();
    isLoading = false;
  });
</script>
<div class="flex flex-wrap mt-4">
  <div class="w-full mb-12 xl:mb-0 px-4">
    <DataTable
      showLoading={isLoading}
      tableTitle={tableTitle}
      headerButtons={[
        {
          text: listType === "system-users" ? "YENİ SİSTEM KULLANICISI OLUŞTUR" : "YENİ KULLANICI OLUŞTUR",
          color: "bg-green-500",
          hoverColor: "hover:bg-green-600",
          activeColor: "active:bg-green-700",
          nextRoute: listType === "system-users" ? "/user-management/system-users/new" : "/user-management/property-owners/new"
        }
      ]}
      columns={tableColumns}
      dataSource={userList}
      rowOperations={rowOperations}
      showPagination={true}
    />
  </div>
</div>
