<script>
    import { Approver, InlineButton } from "../../components/index.svelte";

    export let row = null;
    export let onRowEditClick = null;
    export let onRowDeleteClick = null;
    export let onView = null;
    export let userType = "USER";
</script>
<div>
    <InlineButton text="Görüntüle" buttonType="info" onClick={() => onView(row)} />
    {#if userType === "ADMIN"}
        <InlineButton text="Düzenle" buttonType="edit" onClick={() => onRowEditClick(row)} />  
        <Approver
            title="Uyarı !"
            onApprove={() => onRowDeleteClick(row)}>
            Kullanıcıyı silmek istediğinize emin misiniz ?
        </Approver>
    {/if}
</div>