<script>
    import Show from "../Show/Show.svelte";

    export let labelText = '';
    export let onChange = null;
    export let fieldName = '';
    let fileUploadID = Math.random().toString(36).slice(2, 12);
    let fileNames = '';
    const onFileUploadChange = e => {
        const { files } = e.target;
        fileNames = [...files].map(file => file.name).join(', ');
        if (typeof onChange === 'function') {
            onChange(e);
        }
    }
</script>
<Show condition={labelText}>
    <h1
        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
    >
        {labelText}
    </h1>
</Show>
<div class="flex justify-center items-center w-full">
    <label for={fileUploadID} class="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100">
        <div class="flex flex-col justify-center items-center px-6">
            <svg aria-hidden="true" class="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
            <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Dosya yüklemek için tıklayın</span> ya da sürükleyip bırakın</p>
        </div>
        <input
            on:change={onFileUploadChange}
            id={fileUploadID}
            type="file"
            class="hidden"
            multiple={true}
            name={fieldName}
        />
    </label>
</div>
<Show condition={fileNames}>
    <h1 class="block uppercase text-blueGray-600 text-xs font-bold mt-2">
        {fileNames}
    </h1>
</Show>
