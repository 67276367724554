<script context='module'>
    import Login from "../views/auth/Login.svelte";
    import ForgotPassword from "../views/auth/ForgotPassword.svelte";
    import DashboardMain from "../views/DashboardMain.svelte";
    import Settings from "../views/Settings.svelte";
    import UserList from "../views/user-management/UserList.svelte";
    import UserForm from "../views/user-management/UserForm.svelte";
    import PropertyList from "../views/property-management/PropertyList.svelte";
    import PropertyForm from "../views/property-management/PropertyForm.svelte";
    import NewProperty from "../views/property-management/NewProperty.svelte";
    import EditProperty from "../views/property-management/EditProperty.svelte";
    import ViewProperty from "../views/property-management/ViewProperty.svelte";
    import ReservationForm from "../views/property-management/ReservationForm.svelte";

    const nonAuthRoutes = [
        { path: 'login', component: Login },
        { path: 'forgot-password', component: ForgotPassword }
    ];

    const userRoutes = [
        {
            path:'/',
            param: '*dashboard',
            subRoutes: [
            { path: '', component: DashboardMain },
            { path: 'settings', component: Settings }
            ],
        },
        {
            path:'properties',
            param: '*properties',
            subRoutes: [
                { path: '', component: PropertyList },
                { path: '/new', component: NewProperty },
                { path: '/edit/:propertyID', component: EditProperty },
                { path: '/view/:propertyID', component: ViewProperty },
                { path: '/:propertyID/reservations/new', component: ReservationForm },
                { path: '/:propertyID/reservations/:reservationID/edit', component: ReservationForm },
            ],
        },
    ];

    const adminRoutes = [
        ...userRoutes,
        {
            path:'user-management',
            param: '*userManagement',
            subRoutes: [
                { path: 'property-owners', component: UserList, props: {listType: 'property-owners'} },
                { path: 'property-owners/new', component: UserForm, props: {userType: 'property-owner'} },
                { path: 'property-owners/:userID/edit', component: UserForm, props: {userType: 'property-owner'} },
                { path: 'system-users', component: UserList, props: {listType: 'system-users'} },
                { path: 'system-users/new', component: UserForm, props: {userType: 'system-user'} },
                { path: 'system-users/:userID/edit', component: UserForm, props: {userType: 'system-user'} },
            ],
        },
    ]

    export const getRoutes = (loggedIn = true, isAdmin = true) => {
        if (!loggedIn) {
            return nonAuthRoutes;
        }
        if (isAdmin) {
            return adminRoutes;
        }
        return userRoutes;
    }
</script>