<!-- App.svelte -->
<script>
  import { onMount } from "svelte";
  import { Router, Route, navigate } from "svelte-routing";
  
  import { userStore } from './store/store.index';
  import { getCookie } from "./helpers/utils.svelte";
  import { getLoggedInUser } from "./helpers/apiHelper.svelte";
  
  import Navbar from "./components/Navbars/Navbar.svelte";
  import Sidebar from "./components/Sidebar/Sidebar.svelte";
  import FooterAdmin from "./components/Footers/FooterAdmin.svelte";
  import { getRoutes } from "./helpers/routes.svelte";
  import NotificationProvider from './providers/NotificationProvider.svelte';
  
  export let url = "";
  
  let userData = null;
  let isLoading = true;
  let shouldLogin = true;
  userStore.subscribe(store => {
    userData = store;
  });
  
  onMount(async () => {
    shouldLogin = window?.location?.pathname.indexOf('/auth') < 0;
    if (getCookie("dodouc")) {
      const userResponse = await getLoggedInUser();
      if (userResponse.success && userResponse?.content?.user) {
        userStore.set(userResponse.content.user);
        shouldLogin = false;
      }
    }
    isLoading = false;
    if (shouldLogin) {
      navigate('/login');
    }
  });
  
  function generateRoutePath(path, param = null) {
    let pathParam = [path, param].filter((i1) => i1).join('/');
    pathParam = pathParam.replaceAll('//','/');
    return pathParam;
  }
</script>
<NotificationProvider />
<Router url={url} let:params>
  {#if isLoading}
    <span>Loading</span>
  {:else}
    {#each getRoutes(!shouldLogin, userData.user_type === 'ADMIN') as routes}
      {#if routes?.subRoutes?.length > 0}
        <Route path={generateRoutePath(routes.path, routes?.param)}>
          <div>
            <Sidebar location={location} url={params}/>
            <div class="relative md:ml-64 bg-blueGray-100 min-h-screen flex flex-col justify-between">
              <Navbar/>
              <div class="px-4 md:px-10 mx-auto w-full mt-0 flex-grow">
                <Router url={generateRoutePath(routes.path)}>
                  {#each routes.subRoutes as subRoute}
                    <Route path={generateRoutePath(subRoute.path, subRoute.param)} component={subRoute.component} {...subRoute.props} />
                  {/each}
                </Router>
              </div>
              <FooterAdmin />
            </div>
          </div>
        </Route>
      {:else}
        <Route path={generateRoutePath(routes.path, routes?.param)} component={routes.component} />
      {/if}
    {/each}
  {/if}
</Router>