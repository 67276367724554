<script>
    import Portal from "../Portal/Portal.svelte";


    export let visible = false;
    export let title = "";
    export let footer = null;
    export let noSpacing = false;
    export let fillWidth = false;
    export let onClose = () => null;

    function clickOutside(node) {
      
      const handleClick = event => {
        if (node && !node.contains(event.target) && !event.defaultPrevented) {
          node.dispatchEvent(
            new CustomEvent('clickOutside', node)
          )
        }
      }

      document.addEventListener('click', handleClick, true);
      
      return {
        destroy() {
          document.removeEventListener('click', handleClick, true);
        }
      }
    }

</script>
{#if visible}
<Portal>
  <div
    class="flex h-full justify-center items-center bg-gray-300 bg-opacity-75 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full" >
        <div tabindex="-1" aria-hidden="true">
            <div class="relative p-4 w-full h-full md:h-auto" class:max-w-2xl={!fillWidth} style={fillWidth ? "max-width: 90vw; width: 90vw;" : ""}>
                <div
                  use:clickOutside
                  on:clickOutside={() => {
                    onClose()
                  }} 
                  style="max-height:90vh; overflow: auto"
                  class="relative bg-white rounded-lg shadow min-w-quarter">
                    <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                        {#if title}
                          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                            {title}
                          </h3>
                        {/if}
                        <button on:click={() => onClose()} type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div class="p-6" class:space-y-6={!noSpacing}>
                        <slot />
                    </div>
                    <slot name="footer" />
                    {#if footer}
                      <div class="flex-col w-full flex justify-center p-4">
                        <svelte:component this={footer.component} {...(footer.props || {})}/>
                      </div>
                    {/if}
                </div>
            </div>
        </div>
  </div>
</Portal>
{/if}