<script>
    import { Approver, InlineButton } from "../../components/index.svelte";

    export let row = null;
    export let onRowEditClick = null;
    export let onRowDeleteClick = null;
    export let onNewRatingClick = null;
    export let userType = "USER";
</script>
<div>
    {#if userType === "ADMIN"}
        <InlineButton text="Değerlendirme" buttonType="create" onClick={() => onNewRatingClick(row)} />  
        <InlineButton text="Düzenle" buttonType="edit" onClick={() => onRowEditClick(row)} />  
        <Approver
            title="Uyarı !"
            onApprove={() => onRowDeleteClick(row)}>
            Rezervasyonu silmek istediğinize emin misiniz ?
        </Approver>
    {/if}
</div>