<script>
    import Chart from 'chart.js';


    let id = Math.random().toString(36).slice(2, 12);
    export let data = null;
    $: {
        if (data) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            new Chart(context, {
                type: "line",
                data,
                options: {
                    legend: {
                        display: false
                    }
                }
            });
            document.getElementById(id)?.appendChild(canvas);
        } else {
            const div = document.createElement('div');
            const p = document.createElement('p');
            p.classList.add(...('font-thin text-md'.split(' ')));
            p.innerText = 'Seçilen tarihlere ait veri bulunamadı';
            div.style.height = '200px';
            div.classList.add(...('flex justify-center items-center'.split(' ')));
            div.appendChild(p);
            document.getElementById(id)?.appendChild(div);
        }
    }
</script>
<div id={id}></div>
